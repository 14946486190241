import React, { Fragment, useState , useEffect } from "react";
import axios from "axios";

import swal from "sweetalert";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import PerfectScrollbar from "react-perfect-scrollbar";
import { GlobalSet } from '../../global/GlobalProvider';

import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Wizard = () => {
const [goSteps, setGoSteps] = useState(0);
const { t } = useTranslation();
const { BaseUrl } = GlobalSet();
const claim_button = BaseUrl + '/public/appssource/basic_img/claim_button.gif';
 const [securityCk, setUser] = useState([]);
 useEffect(()=>{
      const auth_token = localStorage.getItem('auth_token');
      const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
      if (auth_token !== null) {
         axios.post('/bonus-home',data)
         .then(response=>{
           setUser(response.data)
           })
           .catch(error=>{
             console.log(error)
         })
      }

  },[])


 const [loading, setLoading] = useState(false);
 const [modalWithTooltip, setModalWithTooltip] = useState(false);
  const handleLinkClick = () => {
      const auth_token = localStorage.getItem('auth_token');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        }
      if (auth_token !== null && !loading) {
         setLoading(true);
         axios.post('/wc-bonus-claim',data)
         .then(res=>{
          setLoading(false);
           if(res.data.status === 'success')
           {
             swal("success",res.data.message,"success");          
           }
           else if(res.data.status === 'fail')
           {
             swal("Warning",res.data.message,"warning");
           }
           else 
           {
             swal("Warning",res.data.message,"warning");
           }
            setLoading(false);
           })
           .catch(error=>{
             console.log(error)
         })
      }

 };


  let id;
  let identity_st;
  let mail_status;
  let sponsor_user_Id;
  let total_deposit;
  let bonus_amount;
  let bet_offer_s;
  let bonus_status;
  let bonus;
  let bonus_amount_x;
  if (securityCk == "") {
    console.log(securityCk);
  }else{
    id=securityCk.data.id;
    identity_st=securityCk.data.identity_status;
    mail_status=securityCk.data.mail_status;
    total_deposit=securityCk.data.total_deposit;
    sponsor_user_Id=securityCk.data.sponsor_user_Id;
    bonus=securityCk.data.bonus;
    if(securityCk.bonus_status != 0){
       bonus_amount=securityCk.bonus_status.bonus_amount;
       bet_offer_s=securityCk.bonus_status.bet_offer_s;
       bonus_status=securityCk.bonus_status.bonus_status;
       bonus_amount_x=securityCk.bonus_status.bonus_amount_x;
    }

  }


	return (
		<Fragment>
      <div className="row">



        <div className="col-xl-6 col-xxl-6 col-lg-6">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h4 className="card-title">Welcome offer Stepper</h4>
            </div>
            <div className="card-body">
              <PerfectScrollbar
                style={{ height: "370px" }}
                id="DZ_W_TimeLine"
                className="widget-timeline dlab-scroll height370 ps ps--active-y"
              >
                <ul className="timeline">
                  <li>
                    <div className={`timeline-badge ${sponsor_user_Id === 1 ? "success" : "dark"}`}></div>
                    <Link
                      className="timeline-panel text-black"
                      to="/affiliate"
                    >
                      <span>Don't Forget to Use the Promo Code!</span>
                      <h6 className="mb-0">
                        Must use promo code{" "}
                      </h6>
                    </Link>
                  </li>

									<li>
									  <div className={`timeline-badge ${identity_st === 1 ? "success" : "dark"}`}></div>
									  <Link className="timeline-panel text-black" to="/security">
									    <span>Complete Your Profile for 100% Verification!</span>
									    <h6 className="mb-0">Profile 100% Verification.</h6>
									  </Link>
									</li>

                  <li>
                    <div className={`timeline-badge ${total_deposit === 0 ? "dark" : "success"}`}></div>
                    <Link
                      className="timeline-panel text-black"
                      to="/deposit"
                    >
                      <span>Deposit $1 to $100 and Start Now!</span>
                      <h6 className="mb-0">
                        Minimum deposit $1 - $100 equivalent{" "}
                      </h6>
                    </Link>
                  </li>

                  <li>
                    <div className="timeline-badge success"></div>
                    <Link
                      className="timeline-panel text-black"
                      to="/"
                    >
                      <span>Enjoy the bonus</span>
                      <h6 className="mb-0">
                        Claim and Enjoy Your Bonus!
                      </h6>
                    </Link>
                  </li>

                </ul>
              </PerfectScrollbar>
            </div>
          </div>
        </div>




        <div className="col-xl-6 col-xxl-6 col-lg-6">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h4 className="card-title">Welcome Offer Cash Stepper</h4>
            </div>
            <div className="card-body">
              <PerfectScrollbar
                style={{ height: "370px" }}
                id="DZ_W_TimeLine"
                className="widget-timeline dlab-scroll height370 ps ps--active-y"
              >
                <ul className="timeline">
                  <li>
                    <div className={`timeline-badge ${ bonus_amount > 0 ? "success" : "dark"}`}></div>
                    <span
                      className="timeline-panel text-black"
                    >
                      <span>Your bonus balance is currently active and ongoing. </span>
                      <h6 className="mb-0">
                        Your bonus amount is ongoing.
                      </h6>
                    </span>
                  </li>

                  <li>
                    <div className={`timeline-badge ${bet_offer_s === 1 ? "success" : "dark"}`}></div>
                    <span className="timeline-panel text-black">
                      <span>A minimum of 3 multi bets must be placed and up to 1.40 odd</span>
                      <h6 className="mb-0">Each must win .</h6>
                    </span>
                  </li>

                  <li>
                    <div className={`timeline-badge ${bonus_amount_x === 1 ? "success" : "dark"}`}></div>
                    <span
                      className="timeline-panel text-black"
                    >
                      <span>The bonus amount must be  5X !</span>
                      <h6 className="mb-0">
                        The bonus amount must be multiplied by 5X{" "}
                      </h6>
                    </span>
                  </li>

                  <li>
                    <div className={`timeline-badge ${bonus_status === 'Complete' ? "success" : "dark"}`}></div>
                    <span
                      className="timeline-panel text-black"
                    >
                      <span>This condition is suitable for use</span>
                      <h6 className="mb-0">
                        Suitable for use!
                      </h6>
                      {bonus_amount_x === 1 && (
                        <>
                         <Link onClick={handleLinkClick}>
                          <img
                            src={claim_button}
                            className="img-fluid mt-2"
                            alt="Claim button"
                            width="100px"
                          />
                         </Link>
                        </>
                      )}
                    </span>
                  </li>

                </ul>

              </PerfectScrollbar>
            </div>
          </div>
        </div>


      

    </div>
		</Fragment>
	);
};

export default Wizard;