import React, { useState, useContext, useEffect } from "react";
import {Nav,Tab} from 'react-bootstrap';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { ThemeContext } from "../../context/ThemeContext";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import axios from "axios";
import swal from "sweetalert";

const Setting = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleLanClick = (divLang) => {
    localStorage.setItem('selectedLanguage', divLang);
    window.location.reload();
  };

  let  divLang;
  const sel_Language = localStorage.getItem('selectedLanguage');
  if (sel_Language !== null) {
   divLang = sel_Language;
  } else {
   divLang = "en";
  }
  const [selectedLanguage, setSelectedLanguage] = useState(divLang);
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };


  const handleCarClick = (divCarr) => {
    localStorage.setItem('selectedCurrency', divCarr);
      let  cur_icon;
	  if (divCarr === "USD") {
	   cur_icon = "$";
	  } else if (divCarr === "EUR"){
	   cur_icon = "€";
	  } else if (divCarr === "BDT"){
	   cur_icon = "৳";
	  } else if (divCarr === "INR"){
	   cur_icon = "₹";
	  }
    localStorage.setItem('CurrencySymbol', cur_icon);
    window.location.reload();
  };
  let  divCarr;
  const sel_cudrrency = localStorage.getItem('selectedCurrency');
  if (sel_cudrrency !== null) {
   divCarr = sel_cudrrency;
  } else {
   divCarr = "USD";
  }
  const [selectedCurrency, setSelectedCurrency] = useState(divCarr);


  const handleBalance = (divBalnce) => {
    localStorage.setItem('balanceMode', divBalnce);
    let  cur_icon;
	  if (divBalnce === "balance") {
	   cur_icon = "balance";
	  } else if (divBalnce === "bonus"){
	   cur_icon = "bonus";
	  }else{
	   cur_icon = "balance";
	  }
	      localStorage.setItem('balanceMode', cur_icon);
        const auth_token = localStorage.getItem('auth_token');
        const data = {
            balance_type: cur_icon,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        }

      if (auth_token !== null) {
          axios.post(`/change-balance-type`, data).then(res => {
                if(res.data.status === 'success')
                {
                   swal("Success",res.data.message,"success");
                }
                else if(res.data.status === 'fail')
                {
                  swal("Warning",res.data.message,"warning");
                }
                else 
                {
                  swal("Warning","Basic Error","warning");
                }
 					   window.location.reload();                
            });
          }

  };
  let  divBalnce;
  const sel_balance = localStorage.getItem('balanceMode');
  if (sel_balance !== null) {
   divBalnce = sel_balance;
  } else {
   divBalnce = "balance";
  }
  const [balanceMode, setSelectedBalance] = useState(divBalnce);
  const [settingToggle, setSettingToggle] = useState(false);
  const [demoToggle, setDemoToggle] = useState(false);
  const {
    body,
    backgroundOption,
    changeBackground,
    background
  } = useContext(ThemeContext);
  return (
	<>
		<div className={`sidebar-right ${settingToggle ? "show" : ""}`}>
			<div className="bg-overlay" onClick={() => setSettingToggle(!settingToggle)}></div>
			<Link to="#" style={{display: "block"}} className="sidebar-right-trigger wave-effect wave-effect-x" onClick={() => setSettingToggle(!settingToggle)} >
				<span><i className="fa fa-cog fa-spin" /></span>
			</Link>
			<Link to="#" className="sidebar-close-trigger" onClick={() => setSettingToggle(!settingToggle)} >
				<span><i className="la-times las"></i></span>
			</Link>
			<div className="sidebar-right-inner">
				<div className="tab-content tab-content-default tabcontent-border">
					<div className="tab-pane fade active show" id="home8" role="tabpanel">
						<div className="admin-settings">
							<h4 className="text-green">Pick your style</h4>
							<Tab.Container defaultActiveKey="Theme">
								<Tab.Content className="">
									<Tab.Pane className="tab-pane fade " eventKey="Theme">
										<div className="admin-settings">
											<div class="container">
											  <div class="row justify-content-md-center">
												  	<div class="col col-lg-2">
														<div className="nav-item d-flex align-items-center">
															<div className="mail-list rounded  mt-4">

															  <Link onClick={() => handleLanClick('en')} className={"list-group-item " + (divLang === 'en'  && "active" )} >
																<i className="fa fa-paper-plane font-18 align-middle me-2"></i>
																English
															  </Link>
															  <Link onClick={() => handleLanClick('fr')} className={"list-group-item " + (divLang === 'fr'  && "active" )}>
																<i className="fa fa-paper-plane font-18 align-middle me-2"></i>
																Français
															  </Link>
															  <Link onClick={() => handleLanClick('bn')} className={"list-group-item " + (divLang === 'bn'  && "active" )}>
																<i className="fa fa-paper-plane font-18 align-middle me-2"></i>
																বাংলা
															  </Link>
															  <Link onClick={() => handleLanClick('hi')} className={"list-group-item " + (divLang === 'hi'  && "active" )}>
																<i className="fa fa-paper-plane font-18 align-middle me-2"></i>
																हिंदी
															  </Link>

															</div>
														</div> 
												    </div>
												 <div class="col col-lg-2">
														<div className="nav-item d-flex align-items-center">
															<div className="mail-list rounded  mt-4">

															  <Link onClick={() => handleCarClick('USD')} className={"list-group-item " + (divCarr === 'USD'  && "active" )} >
																<i className="fa fa-paper-plane font-18 align-middle me-2"></i>
																USD
															  </Link>
															  <Link onClick={() => handleCarClick('EUR')} className={"list-group-item " + (divCarr === 'EUR'  && "active" )}>
																<i className="fa fa-paper-plane font-18 align-middle me-2"></i>
																EUR
															  </Link>
															  <Link onClick={() => handleCarClick('BDT')} className={"list-group-item " + (divCarr === 'BDT'  && "active" )}>
																<i className="fa fa-paper-plane font-18 align-middle me-2"></i>
																BDT
															  </Link>
															  <Link onClick={() => handleCarClick('INR')} className={"list-group-item " + (divCarr === 'INR'  && "active" )}>
																<i className="fa fa-paper-plane font-18 align-middle me-2"></i>
																INR
															  </Link>
														</div>
													 </div>
												  </div>

												  <div class="col col-lg-2">
													<p>Background</p>{" "}
													<Select defaultValue={background} onChange={(e) => changeBackground(e)}
														options={backgroundOption}
														style={{
															lineHeight: "40px",
															color: "#7e7e7e",
															paddingLeft: " 15px",
														}}
													/>
													</div>


												 <div class="col col-lg-2">
														<div className="nav-item d-flex align-items-center">
															<div className="mail-list rounded  mt-4">
															  <Link onClick={() => handleBalance('balance')} className={"list-group-item " + (divBalnce === 'balance'  && "active" )} >
																<i className="fa fa-paper-plane font-18 align-middle me-2"></i>
																Balance
															  </Link>
															  <Link onClick={() => handleBalance('bonus')} className={"list-group-item " + (divBalnce === 'bonus'  && "active" )}>
																<i className="fa fa-paper-plane font-18 align-middle me-2"></i>
																Bonus
															  </Link>
														</div>
													 </div>
												  </div>




												  </div>
											   </div>
											 </div>

									</Tab.Pane>
								</Tab.Content>
							</Tab.Container>
						</div>
					</div>
				</div>
			</div>
		</div>
	
	</>
  );
};

export default Setting;


